import React from "react";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import '../../assets/css/sidebar.scss';
import { FaAngleDown } from "react-icons/fa";

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("bg-black");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("bg-black");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("bg-black");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <div id="smNavBar">
        <Navbar className={"fixed-top"} style={{ background: "black" }}>
          <Container>
            <div className="navbar-translate">
              <NavbarBrand
                href="/index"
                id="navbar-brand"
              >
                <img src={require('../../assets/img/navbar/logo.png').default} className="img-fluid" />
              </NavbarBrand>
              <button
                className="navbar-toggler navbar-toggler"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                type="button"
              >
                <span className="navbar-toggler-bar top-bar"></span>
                <span className="navbar-toggler-bar middle-bar"></span>
                <span className="navbar-toggler-bar bottom-bar"></span>
              </button>
            </div>
            <Collapse
              className="justify-content-end"
              isOpen={collapseOpen}
              navbar
            >
              <Nav navbar className="pt-5">
                <NavItem>
                  <NavLink href="/index">
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-home')}</span>
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav >
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-about')}</span>
                    <FaAngleDown className="dropdownIcon ml-1" />
                  </DropdownToggle>
                  <DropdownMenu className="bg-black">
                    <DropdownItem>
                      <a href="/inspiration">
                        <span className="sm-subNavBar2">{translate(props.lang, 'menu-inspiration')}</span>
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/roadmap">
                        <span className="sm-subNavBar2">{translate(props.lang, 'menu-roadmap')}</span>
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink href="/race">
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-race')} </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/nft">
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-nft')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/tokenomic">
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-toke')}</span>
                  </NavLink>
                </NavItem>

                <UncontrolledDropdown nav>
                  <DropdownToggle nav >
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-gameplay')}</span>
                    <FaAngleDown className="dropdownIcon ml-1" />
                  </DropdownToggle>
                  <DropdownMenu className="bg-black">
                    <DropdownItem>
                      <a href="/game-experience">
                        <span className="sm-subNavBar2">{translate(props.lang, 'menu-play-experience')}</span>
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/play-to-earn">
                        <span className="sm-subNavBar2">{translate(props.lang, 'menu-play-to-earn')}</span>
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a href="/drive-to-earn">
                        <span className="sm-subNavBar2">{translate(props.lang, 'menu-drive-to-earn')}</span>
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavItem>
                  <NavLink href="/team">
                    <span className="sm-subNavBar">{translate(props.lang, 'menu-team')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#pablo">
                    {/* <span className="sm-subNavBar2">Team</span> */}
                    <div className="connectWalletBox text-center">
                      <span className="connectWalletText">{translate(props.lang, 'connect-wallet')}</span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>

    </>
  );
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    mode: auth.mode,
    lang: i18n.lang
  }
}

export default connect(mapStateToProps)(IndexNavbar);