import React from "react";
import { Col, Row } from 'reactstrap';
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaBars, FaTimes } from "react-icons/fa";
import '../../assets/css/sidebar.scss';

function SideBar(props) {
    const [collapseOpen2, setcollapseOpen2] = React.useState(false);
    const [aboutSubItem, setAboutSubItem] = React.useState("none");
    const [gameSubItem, setGameSubItem] = React.useState("none");

    function visibleAboutItem() {
        setAboutSubItem("block");
        setGameSubItem("none");
    }

    function visibleGameItem() {
        setAboutSubItem("none");
        setGameSubItem("block");
    }

    return (
        <div id="lgSideBar">
            <div className="sideBar position-relative">
                <ProSidebar className={collapseOpen2 ? "h-100 position-fixed sideBarExpand" : "h-100 position-fixed sideBarCollapse"} collapsed={true} onToggle={true}>
                    <SidebarHeader>
                        <div className="pt-2 pb-2">
                            <a href="/index">
                                <img src={require('../../assets/img/navbar/logo.png').default} className="img-fluid" />
                            </a>
                        </div>
                    </SidebarHeader>
                    <SidebarContent className="text-center align-items-center position-relative">
                        {
                            collapseOpen2 ?
                                <div className="expandMenuContent w-100">
                                    <div className="headerExpand">
                                        <div className="container pt-5 pl-5 pr-5">

                                            <span className="menuTitle">
                                                {translate(props.lang, 'menu-title')}
                                            </span>
                                            <Row className='align-items-start justify-content-start h-100 pt-5'>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <a href="/index" className="navTextHover"><span className="subNavBar">{translate(props.lang, 'menu-home')} </span> </a><br /><br />
                                                    <span className="subNavBar navTextHover" onClick={() => visibleAboutItem()}>{translate(props.lang, 'menu-about')} </span><br /><br />
                                                    <a href="/race" className="navTextHover"> <span className="subNavBar">{translate(props.lang, 'menu-race')} </span></a> <br /><br />
                                                    <a href="/nft" className="navTextHover"><span className="subNavBar">{translate(props.lang, 'menu-nft')} </span></a> <br /><br />
                                                    <a href="/tokenomic" className="navTextHover"><span className="subNavBar">{translate(props.lang, 'menu-toke')} </span></a> <br /><br />
                                                    <span className="subNavBar navTextHover" onClick={() => visibleGameItem()}>{translate(props.lang, 'menu-gameplay')} </span><br /><br />
                                                    <a href="/team" className="navTextHover"><span className="subNavBar">{translate(props.lang, 'menu-team')} </span></a> <br /><br />
                                                </Col>

                                                <Col lg={5} md={5} sm={12} xs={12}>
                                                    <div className={"d-" + aboutSubItem}>
                                                        <a href="/inspiration" className="navTextHover"><span className="subNavBar2">{translate(props.lang, 'menu-inspiration')} </span></a> <br /><br />
                                                        <a href="/roadmap" className="navTextHover"><span className="subNavBar2">{translate(props.lang, 'menu-roadmap')} </span></a> <br /><br />
                                                    </div>
                                                    <div className={"d-" + gameSubItem}>
                                                        <a href="/game-experience" className="navTextHover"><span className="subNavBar2">{translate(props.lang, 'menu-play-experience')} </span></a> <br /><br />
                                                        <a href="/play-to-earn" className="navTextHover"><span className="subNavBar2">{translate(props.lang, 'menu-play-to-earn')} </span></a> <br /><br />
                                                        <a href="/drive-to-earn" className="navTextHover"><span className="subNavBar2">{translate(props.lang, 'menu-drive-to-earn')} </span></a> <br /><br />
                                                    </div>

                                                </Col>
                                                <Col lg={1} md={1} sm={12} xs={12} className="btn-close">
                                                    <div onClick={() => setcollapseOpen2(!collapseOpen2)}>
                                                        <FaTimes className="text-white h3 navTextHover" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div> :
                                <div className="btn-toggle" onClick={() => setcollapseOpen2(!collapseOpen2)} >
                                    <FaBars className="text-white h3 navTextHover" />
                                </div>
                        }
                    </SidebarContent>
                </ProSidebar>
                <div className="connectWalletDiv">
                    <div className="connectWalletBox">
                        <span className="connectWalletText">{translate(props.lang, 'connect-wallet')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}

export default connect(mapStateToProps)(SideBar);