import React from 'react';
import { Button, Input, Container, Row, Col } from "reactstrap";
import "../../assets/css/playtoearn.scss";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { FaAngleLeft, FaAngleRight, FaMinus, FaPlus } from "react-icons/fa";
import DarkFooter from 'components/Footers/DarkFooter';
import SideBar from 'components/Navbars/SideBar';
import IndexNavbar from 'components/Navbars/IndexNavbar';

function PlayToEarn(props) {

    return (
        <div>
            <SideBar />
            <IndexNavbar />
            <div className="playtoearn_bg">
                <div className="container subContainer pt-5 pl-lg-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center">
                           <Col lg={4} md={12} sm={12} xs={12} className="mb-4">
                                <div className="container text-left">
                                    <div className='lh-1 text-left'>
                                        <span className='playHeaderTitle lh-1'>
                                            {translate(props.lang, 'play-to-earn-title1')}
                                        </span> <br />
                                        <span className='playHeaderTitle lh-1'>
                                            {translate(props.lang, 'play-to-earn-title2')}
                                        </span> <br />
                                        <span className='playHeaderTitle lh-1'>
                                            {translate(props.lang, 'play-to-earn-title3')}
                                        </span> <br />
                                    </div>
                                    <span className="playHeaderDesc">
                                        {translate(props.lang, 'play-to-earn-desc')}
                                    </span>
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} xs={12} className="">
                                <div className="text-center hugeContainer">
                                    <img src={require('../../assets/img/play-to-earn/horse-intro.png').default} className="img-fluid w-100" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="container subContainer pt-5 pb-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-top">
                            <Col lg={5} md={6} sm={12} xs={12} className="">
                                <div className="container text-left mt-3 mb-3">
                                    <div>
                                        <span className="playHeaderDesc">
                                            {translate(props.lang, 'drive-to-earn-desc1')}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5} md={6} sm={12} xs={12} className="">
                                <div className="container text-left mt-3 mb-3">
                                    <div>
                                        <span className="playHeaderDesc">
                                            {translate(props.lang, 'drive-to-earn-desc2')}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(PlayToEarn);