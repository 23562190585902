import React from 'react';
import { Button, Input, Container, Row, Col } from "reactstrap";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { FaAngleRight, FaMinus, FaPlus } from "react-icons/fa";
import useCollapse from 'react-collapsed';

function Accordion4(props) {
    const [isExpanded4, setExpanded4] = React.useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded4 })

    return (
        <div>
            <div id="Accordion1" className={isExpanded4 ? 'pt-2 pb-2 collapseContent' : 'pt-2 pb-2'}>
                <div
                    {...getToggleProps({
                        onClick: () => setExpanded4((prevExpanded4) => !prevExpanded4),
                    })}
                >
                    <Row className="justify-content-center align-items-center">
                        <Col lg={10} md={10} sm={10} xs={10} className="text-left">
                            <span className="homeEarnSubtitle">
                                {translate(props.lang, 'home-earn-subtitle4')}
                            </span>
                        </Col>
                        <Col lg={2} md={2} sm={2} xs={2} className="text-left">
                            <span className="text-white">
                                {isExpanded4 ? < FaMinus /> : <FaPlus />}
                            </span>
                        </Col>
                    </Row>
                </div>
                <section {...getCollapseProps()}>
                    <div className='pt-3 pb-5'>
                        <span className="homeHeaderDesc">
                            {translate(props.lang, 'home-earn-content4')}
                        </span>
                    </div>
                    <div className='pb-3'>
                        <div className='d-flex'>
                            <div>
                                <span className="homeHeaderDesc">
                                    {translate(props.lang, 'home-earn-read')}
                                </span>
                            </div>
                            <div>
                                <FaAngleRight className='text-white h4 ml-1 mt-1 mb-0' />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Accordion4);