import React from 'react';
import { Button, Input, Container, Row, Col } from "reactstrap";
import "../../assets/css/gameexperience.scss";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { FaAngleLeft, FaAngleRight, FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import DarkFooter from 'components/Footers/DarkFooter';
import SideBar from 'components/Navbars/SideBar';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        margin: "0 auto",
        float: "none",
        padding: "0px",
        textAlign: "center",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0,0.5)",
        border: "none",
        zIndex: 99999,
        transition: "1",
        width: window.innerWidth > 960 ? "50%" : window.innerWidth > 667 ? "70%" : "90%",
    },

    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    }
};


function GameExperience(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedIcon, setIcon] = React.useState(1);

    function openModal(index) {
        setIcon(index)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <SideBar />
            <IndexNavbar />
            <div className="gameExperience_bg">
                <div className="container subContainer pt-5 pl-lg-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col lg={4} md={12} sm={12} xs={12} className="mb-4">
                                <div className="container text-left">
                                    <div className='lh-1 text-left'>
                                        <span className='gameExperienceTitle lh-1'>
                                            {translate(props.lang, 'game-experience-title')}
                                        </span> <br />
                                    </div>
                                    <span className="playHeaderDesc">
                                        {translate(props.lang, 'game-experience-desc')}
                                    </span>
                                </div>
                            </Col>
                            <Col lg={7} md={12} sm={12} xs={12} className="">
                                <div className="text-center hugeContainer">
                                    <img src={require('../../assets/img/gameexperience/horse-intro.png').default} className="img-fluid w-100" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="container pt-5 pb-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col lg={12} md={12} sm={12} xs={12} className="">
                                <div className="container text-center mt-3 mb-3">
                                    <div className="text-center position-relative">
                                        <img src={require('../../assets/img/gameexperience/game2.png').default} className="img-fluid" />
                                        <div className='gameicon1 gameicon_container floating'>
                                            <img src={require('../../assets/img/gameexperience/game_icon1.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1'>
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(1)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='gameicon2 gameicon_container floating2'>
                                            <img src={require('../../assets/img/gameexperience/game_icon2.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1'>
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(2)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='gameicon3 gameicon_container floating'>
                                            <img src={require('../../assets/img/gameexperience/game_icon3.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1'>
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(3)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='gameicon4 gameicon_container floating2'>
                                            <img src={require('../../assets/img/gameexperience/game_icon4.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1' >
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(4)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='gameicon5 gameicon_container floating'>
                                            <img src={require('../../assets/img/gameexperience/game_icon5.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1'>
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(5)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='gameicon6 gameicon_container floating2'>
                                            <img src={require('../../assets/img/gameexperience/game_icon6.png').default} className="img-fluid gameicon_img" />
                                            <div className='hoverIcon text-center'>
                                                <span className='gameIconHeader lh-1'>
                                                    {translate(props.lang, 'game-icon-race')}
                                                </span><br />
                                                <span className='gameIconReadMore lh-1' onClick={() => openModal(6)}>
                                                    {translate(props.lang, 'game-icon-readmore')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DarkFooter />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                id="ModalBox4"
            >
                <div className='container gamePopUpBg'>
                    <div className='pt-2 pb-2'>
                        <FaTimes onClick={() => closeModal()} className='position-absolute text-white closeBtn' />
                        <Row className="justify-content-center align-items-center text-left pt-5 pb-5">
                            <Col lg={10} md={10} sm={12} xs={12} className="mb-4">
                                <img src={require('../../assets/img/gameexperience/game_icon' + selectedIcon + '.png').default} className="img-fluid" />
                                <br /><br /><br />
                                <span className='gameDetailsHeader lh-1'>
                                    {translate(props.lang, 'game-icon-race')}
                                </span><br /><br />
                                <span className='gameDetailsDesc lh-1'>
                                    {translate(props.lang, 'game-icon-details1')}
                                </span> <br /><br />
                                <span className='gameDetailsDesc lh-1'>
                                    {translate(props.lang, 'game-icon-details2')}
                                </span>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Modal>
        </div >

    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(GameExperience);