import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter";
import HomePage from 'views/Dexino/HomePage';
import Team from 'views/Dexino/Team';
import Tokenomics from 'views/Dexino/Tokenomics';
import NFT from 'views/Dexino/NFT';
import About from 'views/Dexino/About';
import PlayToEarn from 'views/Dexino/PlayToEarn';
import DriveToEarn from 'views/Dexino/DriveToEarn';
import GameExperience from 'views/Dexino/GameExperience';
import Roadmap from 'views/Dexino/Roadmap';
import Race from 'views/Dexino/Race';

export default function App() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  },[]);

  return (
    <>
        {/* <IndexNavbar /> */}
        <div className="wrapper">
            <div className="main">
                <Switch>
                    <Route path="/index" render={(props) => <HomePage {...props} />} />
                    <Route path="/team" render={(props) => <Team {...props} />} />
                    <Route path="/tokenomic" render={(props) => <Tokenomics {...props} />} />
                    <Route path="/nft" render={(props) => <NFT {...props} />} />
                    <Route path="/inspiration" render={(props) => <About {...props} />} />
                    <Route path="/play-to-earn" render={(props) => <PlayToEarn {...props} />} />
                    <Route path="/drive-to-earn" render={(props) => <DriveToEarn {...props} />} />
                    <Route path="/game-experience" render={(props) => <GameExperience {...props} />} />
                    <Route path="/roadmap" render={(props) => <Roadmap {...props} />} />
                    <Route path="/race" render={(props) => <Race {...props} />} />

                    <Redirect to="/index" />
                    <Redirect from="/" to="/index" />
                </Switch>
            </div>
            {/* <DarkFooter /> */}
        </div>
    </>
  )
}