import React from 'react';
import { Button, Input, Container, Row, Col } from "reactstrap";
import "../../assets/css/roadmap.scss";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { FaAngleLeft, FaAngleRight, FaMinus, FaPlus } from "react-icons/fa";
import useCollapse from 'react-collapsed';
import Accordion2 from './Accordion/Accordion2';
import Accordion3 from './Accordion/Accordion3';
import Accordion4 from './Accordion/Accordion4';
import Accordion5 from './Accordion/Accordion5';
import Accordion6 from './Accordion/Accordion6';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import inactive1 from "../../assets/img/roadmap/item1.png";
import inactive2 from "../../assets/img/roadmap/item2.png";
import inactive3 from "../../assets/img/roadmap/item3.png";

import DarkFooter from 'components/Footers/DarkFooter';
import SideBar from 'components/Navbars/SideBar';
import IndexNavbar from 'components/Navbars/IndexNavbar';

// const EventArray =
//     [
//         { image: inactive1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//         { image: inactive2, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//         { image: inactive3, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//         { image: inactive1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//         { image: inactive2, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//         { image: inactive3, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
//     ]

function Roadmap(props) {
    const [imageIndex, setImageIndex] = React.useState(0);

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow next bg-transparent" onClick={onClick}>
                <div>
                    <FaAngleRight className='text-white h2 mb-0' />
                </div>
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prev bg-transparent" onClick={onClick}>
                <div>
                    <FaAngleLeft className='text-white h2 mb-0' />
                </div>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 1024? 5 : window.innerWidth > 767 ? 3 : 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    return (
        <div>
            <SideBar />
            <IndexNavbar />
            <div className="roadmap_bg" >
                <div className="pt-5 pl-lg-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center ml-0 mr-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="">
                                <div className='pb-3 text-center container sliderContainer'>
                                    <div className="container text-left lh-1">
                                        <span className='roadmapHeaderTitle'>
                                            {translate(props.lang, 'roadmap-header-title1')}
                                        </span> <br />
                                        <span className='roadmapHeaderTitle'>
                                            {translate(props.lang, 'roadmap-header-title2')}
                                        </span> <br />
                                    </div>
                                    <div className='pt-5 pb-5' id="roadmapSlider">
                                        <div className='pt-5 pb-5'>
                                            <img src={require('../../assets/img/roadmap/border1.png').default} className="img-fluid" />
                                        </div>
                                        <div className='pt-5 pb-5'>
                                            <Slider {...settings}>
                                                <div className={imageIndex === 0 ? "slide2 activeSlide" : imageIndex === 4 || imageIndex === 5 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item1.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={imageIndex === 1 ? "slide2 activeSlide" : imageIndex === 5 || imageIndex === 0 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item2.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={imageIndex === 2 ? "slide2 activeSlide" : imageIndex === 0 || imageIndex === 1 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item3.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={imageIndex === 3 ? "slide2 activeSlide" : imageIndex === 1 || imageIndex === 2 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item1.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={imageIndex === 4 ? "slide2 activeSlide" : imageIndex === 2 || imageIndex === 3 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item2.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={imageIndex === 5 ? "slide2 activeSlide" : imageIndex === 3 || imageIndex === 4 ? "slide3" : "slide2"}>
                                                    <div className="position-relative">
                                                        <img src={require('../../assets/img/roadmap/item3.png').default} className="img-fluid w-100" />
                                                        <div className='position-absolute roadmapContent'>
                                                            <span className='roadmap-phase'>{translate(props.lang, 'roadmap-1-phase')}</span> <br />
                                                            <span className='roadmap-year'>{translate(props.lang, 'roadmap-1-year')}</span>  <br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details1')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details2')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details3')}</span>  <br /><br />
                                                            <span className='roadmap-details'>{translate(props.lang, 'roadmap-1-details4')}</span>  <br /><br />
                                                        </div>
                                                    </div>
                                                </div>

                                            </Slider>
                                        </div>
                                        <div className='pt-5 pb-5'>
                                            <img src={require('../../assets/img/roadmap/border2.png').default} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </div>

    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Roadmap);