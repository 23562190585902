import IndexNavbar from 'components/Navbars/IndexNavbar';
import SideBar from 'components/Navbars/SideBar';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { translate } from 'utils/translate';
import "../../assets/css/nft.scss";

const NFT = (props) => {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });


    return (
        <>
            <SideBar />
            <IndexNavbar />
            <div className="container-fluid nftBg">
                <Row className="justify-content-center align-items-center h-100">
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                        <img src={require('../../assets/img/logo.png').default} className="img-fluid logo" />
                        <div className="mt-4">
                            <span className="team-title">{translate(props.lang, "nft")}</span>
                            <span className="team-title">{translate(props.lang, "coming soon")}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(NFT);