import React from "react";
import { Button, Input, Container, Row, Col } from "reactstrap";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import "../../assets/css/footer.scss";
import { FaTelegramPlane, FaDiscord, FaMedium, FaTwitter, FaYoutubeSquare, FaRedditAlien, FaAngleRight } from "react-icons/fa";

function DarkFooter(props) {
  return (
    <footer className="footer bg-transparent py-5">
      <div className="container footerContainer">
        <Row className="h-100 pt-3 pb-3 justify-content-center align-items-center">
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            <div className="container text-center">
              <img src={require('../../assets/img/footer/logo.png').default} className="img-fluid" /> <br />
              <span className="copyrightText">{translate(props.lang, 'footer-copyright')}</span>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12} className="text-center text-lg-left text-md-left">
            <span className="subscribeUsText">{translate(props.lang, 'footer-subscribe')}</span>
            <div className="mx-auto inputContainer">
              <Row className="justify-content-center align-items-end w-10 text-center">
                <Col lg={10} md={7} sm={11} xs={11} className="p-0">
                  <Input
                    placeholder="ENTER YOUR EMAIL"
                    type="text"
                    className="inputField"
                  />
                  <img src={require('../../assets/img/footer/border.png').default} className="img-fluid w-100" /> <br />
                </Col>
                <Col lg={2} md={4} sm={12} xs={12} className="pb-1 text-right p-0">
                  <div className='d-flex justify-content-center pt-4'>
                    <div className='headerButtonBox text-left'>
                      <span className='homeHeader-buttonText'>
                        {translate(props.lang, 'footer-submit')}
                      </span>
                    </div>
                    <div className="p-3 headerButtonBox2">
                      <FaAngleRight className='text-white h3 mb-0' />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="align-items-center my-4 pt-4">
              <Col lg={2} md={6} sm={12} xs={12} className="text-center text-lg-left mb-3">
                <span className="footer-menu">Gameplay</span>
              </Col>
              <Col lg={1} md={6} sm={12} xs={12} className="text-center mb-3">
                <span className="footer-menu">Faq</span>
              </Col>
              <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-3">
                <span className="footer-menu">Privacy Policy</span>
              </Col>
              <Col lg={5} md={6} sm={12} xs={12} className="text-center mb-3">
                <span className="footer-menu">Terms and Conditions</span>
              </Col>
            </Row>
            <Row>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaTwitter className="social-media-icon" />
              </Col>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaDiscord className="social-media-icon" />
              </Col>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaTelegramPlane className="social-media-icon" />
              </Col>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaMedium className="social-media-icon" />
              </Col>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaYoutubeSquare className="social-media-icon" />
              </Col>
              <Col lg={1} md={4} sm={4} xs={4}>
                <FaRedditAlien className="social-media-icon" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    mode: auth.mode,
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(DarkFooter);