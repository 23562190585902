import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { translate } from 'utils/translate';
import DarkFooter from 'components/Footers/DarkFooter';
import "../../assets/css/about.scss";
import SideBar from 'components/Navbars/SideBar';
import IndexNavbar from 'components/Navbars/IndexNavbar';

const About = (props) => {
    return (
        <>
            <SideBar />
            <IndexNavbar />
            <div className="aboutUs">
                <div className="container-fluid section1">
                    <div className="container vh-100">
                        <Row className='align-items-center justify-content-start h-100'>
                            <Col lg={5} md={8} sm={12} xs={12}>
                                <span className="section1_title">{translate(props.lang, "about")}</span>
                                <span className="section1_title">{translate(props.lang, "dexino")}</span>
                                <div className="mt-5">
                                    <span className="section1_desc">{translate(props.lang, "DEXINO is a virtual horse racing GameFi and DrivingFi platform that is equipped with a play-to-earn game economy to enable racehorse enthusiasts to buy, collect, breed, and race NFT horses in the Dexi-verse.")}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <div className="middle">
                    <img src={require('../../assets/img/about/Group 96.png').default} />
                </div> */}
                <div className="intersection2and3 position-relative">

                    <div className="container-fluid section2">
                        <div className="container pb-4">
                            <Row className='align-items-end justify-content-between h-100'>
                                <Col lg={5} md={5} sm={12} xs={12} className="mb-5">
                                    <div>
                                        <img src={require('../../assets/img/about/mission.png').default} className="img-fluid missionImg" />
                                    </div>
                                    <div className="mt-3">
                                        <span className="section2_title">{translate(props.lang, "mission")}</span>
                                    </div>
                                    <div className="mt-4">
                                        <span className="section2_desc">{translate(props.lang, "Our mission is to let every individual to enjoy DEXINO through fun gameplay and earn token rewards for their contributions to the ecosystem.")}</span>
                                        <span className="section2_desc mt-3">{translate(props.lang, "Players no longer have to travel to far places to visit racecourses for seeing horse racing. We are committed to bringing the most real-life virtual experience to all of our beloved players.")}</span>
                                    </div>
                                </Col>
                                <Col lg={5} md={5} sm={12} xs={12} className="mb-5">
                                    <div>
                                        <img src={require('../../assets/img/about/vision.png').default} className="img-fluid missionImg" />
                                    </div>
                                    <div className="mt-3">
                                        <span className="section2_title">{translate(props.lang, "vision")}</span>
                                    </div>
                                    <div className="mt-4">
                                        <span className="section2_desc">{translate(props.lang, "our vision to become the largest decentralized digital racecourses in the Metaverse while connecting players from worldwide and creating economic prosperity together.")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="container-fluid section3 position-relative">
                        <div className="container inspirationContainer px-5 position-relative">
                            <Row className='align-items-center justify-content-center justify-content-lg-start justify-content-md-start h-100 contentBg'>
                                <Col lg={7} md={12} sm={12} xs={12} className="pt-5 pb-5 px-2 px-lg-5 px-md-4 text-center text-lg-left">
                                    <span className="section3_title">{translate(props.lang, "Inspiration")}</span>
                                    <div className="mt-1">
                                        <span className="section3_desc">{translate(props.lang, "DEXINO is a virtual horse-racing GameFi platform inspired by ZED RUN. Horse racing is one of the most ancient of all sports.")}</span>
                                        <span className="section3_desc mt-3">{translate(props.lang, "By leveraging on the strength of NFTs, we are building a highly secure ecosystem that will help racehorse enthusiasts to buy, collect, breed, and race digitally and inexpensively while in the comfort of their place.")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <div className="horse position-absolute">
                                <img src={require('../../assets/img/about/inspirationHorse.png').default} className="horseImg" />
                            </div>
                        </div>
                        <div className="corner position-absolute">
                            <img src={require('../../assets/img/about/cornerBottom.png').default} className="cornerImg" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid section4">
                    <div className="container-fluid position-relative">
                        <div className="container">
                            <Row className='align-items-center justify-content-start h-100'>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <span className="section4_title">{translate(props.lang, "key highlights")}</span>
                                    <div className="mt-5">
                                        <Row className="className='align-items-center justify-content-between">
                                            <Col lg={5} md={5} sm={12} xs={12}>
                                                <span className="section4_desc">{translate(props.lang, "The worldwide online gaming industry was valued at USD 56 billion in 2021, revenues are still growing and will reach USD 203.1 billion through consumer spending (+5.4% YoY).")}</span>
                                            </Col>
                                            <Col lg={5} md={5} sm={12} xs={12}>
                                                <span className="section4_desc">{translate(props.lang, "By the end of 2022, there will be 2.7 billion gamers worldwide, with 2.5 billion playing on mobile, 1.3 billion playing on PC, and 0.8 billion on consoles. The industry market is still growing and it is expected to reach USD 222.6 billion by 2024 or even surpass the three-billion mark, expanding at a CAGR of 5.6% (2020 to 2024).")}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="keyHighlight-Bg">
                            <img src={require('../../assets/img/about/textureBg.png').default} />
                        </div>
                    </div>
                    <div className="container">
                        <Row className='align-items-center justify-content-start h-100'>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                <img src={require('../../assets/img/about/keyHightlight.png').default} className="img-fluid" />
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="mt-5">
                                    <Row className="className='align-items-center justify-content-between">
                                        <Col lg={5} md={5} sm={12} xs={12}>
                                            <span className="section4_desc">{translate(props.lang, "In spite of economic downturns and businesses across the country closing, the online gaming sector remained active and even busy. The increased presence of young professional gamers and internet penetrations have contributed to the rapid growth of the gaming industry. In 2021, the Asia-Pacific region accounted for more than 50% of the online gaming market share.")}</span>
                                            <span className="section4_desc mt-3">{translate(props.lang, "This has proven that there is a huge opportunity in the market for online games, precisely, games with play-to-earn features. However, in the current market, the digital horse racing platform often comes with single or limited gameplay, which makes the player feel tedious and mundane, and they gradually quit the game.")}</span>
                                        </Col>
                                        <Col lg={5} md={5} sm={12} xs={12}>
                                            <span className="section4_desc">{translate(props.lang, "On the other hand, the cost of having an upgrade and breeding new offspring can be prohibitive for many people. They might find the transactions expensive due to the currency exchange.")}</span>
                                            <span className="section4_desc mt-3">{translate(props.lang, "DEXINO offers people more than just a play-to-earn platform. By eliminating the limits of primitive racecourses, in DEXINO, players are allowed to not only breed and race their digital horses, but they can also own a DEXI-land where they can farm hay and host races on their own virtual racecourse for actual profit. Earning is greater than spending in DEXINO. With the support of the decentralized autonomous organization (DAO) protocol, players are safeguarded for transparency, fairness, and safety in the game.")}</span>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
                                <img src={require('../../assets/img/about/keyHighlight2.png').default} className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="footer-section pt-5">
                    <DarkFooter />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(About);