import { LOGIN, LOGOUT } from "./action";

const defaultState = {
    mode: "dark",
};

const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                ...{
                    mode: action.mode,
                }
            }
        case LOGOUT:
            return {
                ...state,
                mode: null,
            }
        default:
            return state
    }
}

export default authReducer;