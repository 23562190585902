import React from 'react';
import { Button, Input, Container, Row, Col } from "reactstrap";
import "../../assets/css/home.scss";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { FaAngleLeft, FaAngleRight, FaMinus, FaPlus } from "react-icons/fa";
import useCollapse from 'react-collapsed';
import Accordion2 from './Accordion/Accordion2';
import Accordion3 from './Accordion/Accordion3';
import Accordion4 from './Accordion/Accordion4';
import Accordion5 from './Accordion/Accordion5';
import Accordion6 from './Accordion/Accordion6';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import livestream1 from "../../assets/img/home/livestream1.png";
import horseSlide1 from "../../assets/img/home/horse-slide1.png";
import horseSlide2 from "../../assets/img/home/horse-slide2.png";
import horseSlide3 from "../../assets/img/home/horse-slide3.png";
import DarkFooter from 'components/Footers/DarkFooter';
import SideBar from 'components/Navbars/SideBar';
import IndexNavbar from 'components/Navbars/IndexNavbar';

const EventArray =
    [
        { image: livestream1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
        { image: livestream1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
        { image: livestream1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
        { image: livestream1, nation: "US", eventName: "ST.LOUIS", class: "Class IV", gates: "5 Gates Left", price: "$5.25", currency: "USD" },
    ]

const HorseArray =
    [
        { image: horseSlide1, key: "01", title: "HORSE 1", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
        { image: horseSlide2, key: "02", title: "HORSE 2", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
        { image: horseSlide3, key: "03", title: "HORSE 3", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." },
    ]

function HomePage(props) {
    const [isExpanded4, setExpanded4] = React.useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded4 })
    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow next bg-transparent" onClick={onClick}>
                <div>
                    <FaAngleRight className='text-white h2 mb-0' />
                </div>
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prev bg-transparent" onClick={onClick}>
                <div>
                    <FaAngleLeft className='text-white h2 mb-0' />
                </div>
            </div>
        );
    };

    const NextArrow2 = ({ onClick }) => {
        return (
            <div className="arrow next bg-transparent" onClick={onClick}>
                <div className='circle'>
                    <FaAngleRight className='blueBtn h3 mb-0' />
                </div>
            </div>
        );
    };

    const PrevArrow2 = ({ onClick }) => {
        return (
            <div className="arrow prev bg-transparent" onClick={onClick}>
                <div className='circle'>
                    <FaAngleLeft className='blueBtn h3 mb-0' />
                </div>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 767 ? 3 : 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };


    const settings2 = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 960 ? 2 : 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow2 />,
        prevArrow: <PrevArrow2 />,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    },[]);


    return (
        <div className=''>
            <SideBar />
            <IndexNavbar />
            <div className="header_bg">
                <div className="pt-5 pl-lg-5 sectionPart">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center ml-0 mr-0 reverse-column">
                            <Col lg={5} md={5} sm={12} xs={12} className="">
                                <div className="container text-left">
                                    <div className='lh-1 mb-3 mt-3'>
                                        <span className='homeHeaderTitle'>
                                            {translate(props.lang, 'home-header-title')}
                                        </span>
                                    </div>
                                    <span className="homeHeaderDesc">
                                        {translate(props.lang, 'home-header-content')}
                                    </span>
                                    <div className='d-flex pt-4'>
                                        <div className='headerButtonBox'>
                                            <span className='homeHeader-buttonText'>
                                                {translate(props.lang, 'home-header-button')}
                                            </span>
                                        </div>
                                        <div className="p-3 headerButtonBox2">
                                            <FaAngleRight className='text-white h3 mb-0' />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={7} md={7} sm={12} xs={12} className="">
                                <div className="container text-center">
                                    <img src={require('../../assets/img/home/horse1.png').default} className="img-fluid w-100" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="intro_bg" >
                <div className="pt-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center ml-0 mr-0">
                            <Col lg={10} md={12} sm={12} xs={12} className="text-center">
                                <Row className="h-100 justify-content-center align-items-center ml-0 mr-0">
                                    <Col lg={8} md={6} sm={12} xs={12} className="text-center">
                                        <div className="container text-center">
                                            <img src={require('../../assets/img/home/intro_horse.png').default} className="img-fluid w-100" />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className="text-center">
                                        <div className="container text-left">
                                            <div className='lh-1'>
                                                <span className='homeHeaderTitle'>
                                                    {translate(props.lang, 'home-intro-title')}
                                                </span>
                                            </div>
                                            <br />
                                            <span className="homeHeaderDesc">
                                                {translate(props.lang, 'home-intro-desc1')}
                                            </span> <br /><br />
                                            <span className="homeHeaderDesc">
                                                {translate(props.lang, 'home-intro-desc2')}
                                            </span>

                                            <div className='d-flex pt-4'>
                                                <div className='headerButtonBox'>
                                                    <span className='homeHeader-buttonText'>
                                                        {translate(props.lang, 'home-intro-button')}
                                                    </span>
                                                </div>
                                                <div className="p-3 headerButtonBox2">
                                                    <FaAngleRight className='text-white h3 mb-0' />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="pt-5 pb-5">
                    <div className="h-100">
                        <Row className="h-100 justify-content-center align-items-center ml-0 mr-0">
                            <Col lg={10} md={10} sm={12} xs={12} className="text-center">
                                <div className="container text-left">
                                    <span className='homeHeaderTitle'>
                                        {translate(props.lang, 'home-earn-title')}
                                    </span>
                                </div>
                                <Row className="h-100 pt-3 pb-3 justify-content-center align-items-center ml-0 mr-0">
                                    <Col lg={7} md={7} sm={12} xs={12} className="text-center">
                                        <div className="container text-center mb-4">
                                            <img src={require('../../assets/img/home/earn_horse.png').default} className="img-fluid" />
                                        </div>
                                    </Col>
                                    <Col lg={5} md={5} sm={12} xs={12} className="text-center">
                                        <Row className="h-100 justify-content-center align-items-center ml-0 mr-0">
                                            <Col lg={10} md={12} sm={12} xs={12} className="text-center">
                                                <div className="text-left">
                                                    <div id="Accordion1" className={isExpanded4 ? 'pt-2 pb-2 collapseContent' : 'pt-2 pb-2'}>
                                                        <div
                                                            {...getToggleProps({
                                                                onClick: () => setExpanded4((prevExpanded4) => !prevExpanded4),
                                                            })}
                                                        >
                                                            <Row className="justify-content-center align-items-center">
                                                                <Col lg={10} md={10} sm={10} xs={10} className="text-left">
                                                                    <span className="homeEarnSubtitle">
                                                                        {translate(props.lang, 'home-earn-subtitle1')}
                                                                    </span>
                                                                </Col>
                                                                <Col lg={2} md={2} sm={2} xs={2} className="text-left">
                                                                    <span className="text-white">
                                                                        {isExpanded4 ? < FaMinus /> : <FaPlus />}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <section {...getCollapseProps()}>
                                                            <div className='pt-3 pb-5'>
                                                                <span className="homeHeaderDesc">
                                                                    {translate(props.lang, 'home-earn-content1')}
                                                                </span>
                                                            </div>
                                                            <div className='pb-3'>
                                                                <div className='d-flex'>
                                                                    <div>
                                                                        <span className="homeHeaderDesc">
                                                                            {translate(props.lang, 'home-earn-read')}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <FaAngleRight className='text-white h4 ml-1 mt-1 mb-0' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                    <Accordion2 />
                                                    <Accordion3 />
                                                    <Accordion4 />
                                                    <Accordion5 />
                                                    <Accordion6 />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='pt-5 pb-5 home-livestream'>
                    <div className="container text-center">
                        <span className='homeHeaderTitle'>
                            {translate(props.lang, 'home-live-title')}
                        </span>
                    </div>
                    <div className='pt-3 pb-1 text-center'>
                        <img src={require('../../assets/img/home/live-curveSlider.png').default} className="img-fluid" />
                    </div>
                    <div className='pb-3 text-center container' id="liveStreamSlider">
                        <div className='pb-3'>
                            <span className="homeLiveSubtitle">
                                {translate(props.lang, 'home-livesoon-subtitle')}
                            </span>
                        </div>
                        <Slider {...settings}>
                            {
                                EventArray.map((item, i) => (
                                    <div>
                                        <div key={i} className={imageIndex === i ? "slide2 activeSlide" : "slide2"}>
                                            <div className="position-relative">
                                                <img src={item.image} className="img-fluid w-100" />
                                                <div className="glowEffect w-100 h-100" />
                                                <div className='buttonGlowEffect position-relative w-100 h-100'>
                                                    <div className='slider-EnterHover pt-3'>
                                                        <span className='slideEnterText text-black'>ENTER</span>
                                                    </div>
                                                </div>
                                                <div className='sliderContent text-left'>
                                                    <div className='pl-4 position-relative w-100 h-100'>
                                                        <div className='slide-titleDiv'>
                                                            <span className='slideNation'>{item.nation}</span><br />
                                                            <span className='slideTitle'>{item.eventName}</span><br />
                                                            <span className='slideClass'>{item.class}</span>
                                                        </div>
                                                        <div className='slide-bottomDiv'>
                                                            <Row className="justify-content-center align-items-center ml-0 mr-0">
                                                                <Col lg={9} md={7} sm={9} xs={9} className="text-left">
                                                                    <span className='slideGates'>{item.gates}</span>
                                                                </Col>
                                                                <Col lg={3} md={5} sm={3} xs={3} className="text-right">
                                                                    <span className='slidePrice'>{item.price}</span>
                                                                    <span className='slideCurrency'>&nbsp;{item.currency}</span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                        <div className='pt-3 pb-3'>
                            <div className='d-flex justify-content-center'>
                                <div>
                                    <span className="homeHeaderDesc">
                                        {translate(props.lang, 'home-more-race')}
                                    </span>
                                </div>
                                <div>
                                    <FaAngleRight className='text-white h4 ml-1 mt-1 mb-0' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='home-horseSliderBG'>
                    <div className='pt-5 home-horseSlider'>
                        <Slider {...settings2}>
                            {
                                HorseArray.map((item, i) => (
                                    <div>
                                        <div key={i} className={imageIndex2 === i ? "slide2 activeSlide" : "slide2"}>
                                            <div>
                                                <Row className='ml-0 mr-0'>
                                                    <Col lg={8} md={8} sm={12} xs={12} className="text-left">
                                                        <img src={item.image} className="img-fluid w-100" />
                                                    </Col>
                                                    <Col lg={4} md={4} sm={12} xs={12} className="text-left">
                                                        <Container>
                                                            <div className='horseDetails'>
                                                                <span className='slideHorseIndex'>{item.key}</span><br />
                                                                <span className='slideHorseName'>{item.title}</span><br />
                                                                <span className='slideHorseDesc'>{item.desc}</span>
                                                                <div className='borderLine'>
                                                                    <img src={require('../../assets/img/home/horse-slideLine.png').default} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    <div className='text-center pb-5'>
                        <span className='homeElementTitle'>
                            {translate(props.lang, 'home-header-elements')}
                        </span> <br />
                        <span className='homeElementDesc'>
                            {translate(props.lang, 'home-desc-elements1')}
                        </span> <br />
                        <span className='homeElementDesc'>
                            {translate(props.lang, 'home-desc-elements2')}
                        </span>
                    </div>
                </div>

                <div className='home-hashBG'>
                    <div className='pt-5 pb-5'>
                        <div className='home-coinBG'>
                            <Row className="justify-content-center align-items-center ml-0 mr-0">
                                <Col lg={4} md={5} sm={12} xs={12} className="text-center">
                                    <Container>
                                        <div>
                                            <span className="homeHashTitle">
                                                {translate(props.lang, 'home-hash-title')}
                                            </span> <br />
                                            <span className="homeHashSubtitle">
                                                {translate(props.lang, 'home-hash-subtitle')}
                                            </span>
                                        </div>
                                        <div>
                                            <div className='d-flex pt-4 pl-5 justify-content-center'>
                                                <div className='HashButtonBox'>
                                                    <span className='homeHeader-buttonText'>
                                                        {translate(props.lang, 'home-hash-button')}
                                                    </span>
                                                </div>
                                                <div className="p-3 hashButtonBox2">
                                                    <FaAngleRight className='text-white h3 mb-0' />
                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                </Col>
                                <Col lg={8} md={7} sm={12} xs={12} className="text-center">
                                    <img src={require('../../assets/img/home/hash_card.png').default} className="img-fluid" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <DarkFooter />
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        mode: auth.mode,
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(HomePage);