import IndexNavbar from 'components/Navbars/IndexNavbar';
import SideBar from 'components/Navbars/SideBar';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { translate } from 'utils/translate';
import "../../assets/css/race.scss";

const Race = (props) => {
    const [iconPills, setIconPills] = React.useState("1");
    const [pills, setPills] = React.useState("1");

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });


    return (
        <>
            <SideBar />
            <IndexNavbar />
            <div className="container-fluid raceBg">
                <Row className="justify-content-start align-items-start h-100">
                    <Col lg={12} md={12} sm={12} xs={12} className="text-left">
                        <div className="mt-4">
                            <Nav tabs className="nav-tabs-neutral justify-content-left" role="tablist">
                                <NavItem>
                                    <NavLink id="partnershipOption"
                                        className={pills === "1" ? "active" : ""}
                                        onClick={() => { setPills("1") }}
                                    >
                                        <img src={require('../../assets/img/race/race_icon.png').default} className="img-fluid mr-2 tab-icon" />
                                        <span className='main-tab-title'>{translate(props.lang, "race-title")}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="partnershipOption"
                                        className={pills === "2" ? "active" : ""}
                                        onClick={() => { setPills("2") }}
                                    >
                                        <img src={require('../../assets/img/race/result_icon.png').default} className="img-fluid mr-2 tab-icon" />
                                        <span className='main-tab-title'>{translate(props.lang, "results-title")}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="tab-content-container pt-5">
                                <TabContent activeTab={pills}>
                                    <TabPane tabId="1" style={{ width: "100%", justifyContent: "center" }}>
                                        <Row className="justify-content-left align-items-center pt-4 pb-4">

                                            <Col md={11} xs={12} className="px-3">
                                                <span className='race-content-title'>{translate(props.lang, "race-title")}</span>

                                                <div className='pt-5 table-responsive'>
                                                    <table class="table table-dark">
                                                        <thead>
                                                            <tr className='text-left'>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-entryrequirement")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-racename")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-hippodrome")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-distance")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-entryfee")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-registerends")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-prize")}</th>
                                                                <th scope="col" className='tableHeadText'>{translate(props.lang, "table-registered")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <div className='pt-5'>
                                                                <span className='race-content-comingsoon'>{translate(props.lang, "race-comingsoon")}</span>
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                                        <Row className="justify-content-left align-items-center pt-4 pb-4">
                                            <div className='pt-1'>
                                                <span className='race-content-comingsoon'>{translate(props.lang, "race-comingsoon")}</span>
                                            </div>

                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(Race);