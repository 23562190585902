const EN = {
    
    //Navbar
    "menu-title": "Menu",
    "menu-home": "Home",
    "menu-about": "About",
    "menu-race": "Race",
    "menu-nft": "NFT",
    "menu-toke": "Tokenomic",
    "menu-gameplay": "GamePlay",
    "menu-team": "Team",
    "menu-inspiration": "Inspirtation",
    "menu-roadmap": "Roadmap",
    "connect-wallet": "Connect Wallet",
    "menu-play-experience": "Game Experience",
    "menu-play-to-earn": "Play-To-Earn",
    "menu-drive-to-earn": "Drive-To-Earn",


    ///Footer
    "footer-copyright": "All Right Reserved. © Copyright 2022",
    "footer-subscribe": "SUBSCRIBE US",
    "footer-submit": "SUBMIT",
    
    ///Home
    "home-header-title": "Equip your digital horse and showcase your unique skills to win!",
    "home-header-content": "DEXINO is a virtual horse-racing GameFi + DrivingFi platform built with a highly secure ecosystem, allowing racehorse enthusiasts to buy NFT horses, collect, breed, and race digitally.",
    "home-header-button": "SIGN UP NOW!",
    "home-intro-title": "First GameFi + DrivingFi Platform",
    "home-intro-desc1": "We are backed by a team specializes in web3 game development, with over 30 members over the globe in charge of different areas of the project. DEXINO actively advocates—earning is greater than spending.",
    "home-intro-desc2": "Enjoy fun gameplay through our outstanding P2E mechanism and earn NFT and token rewards.",
    "home-intro-button": "Read More",
    "home-earn-title": "PLAYERS CAN EARN",
    "home-earn-subtitle1": "RACING",
    "home-earn-subtitle2": "BREEDING",
    "home-earn-subtitle3": "LAND/FARM",
    "home-earn-subtitle4": "COLLECTING",
    "home-earn-subtitle5": "DRIVING",
    "home-earn-subtitle6": "TOURNAMENT",
    "home-earn-content1": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-content2": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-content3": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-content4": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-content5": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-content6": "Own a racehorse, dominate the competition and win tokens.",
    "home-earn-read": "Read More",
    "home-live-title": "STREAMING NOW!",
    "home-livesoon-subtitle": "UP AND COMING",
    "home-more-race": "More Races",

    "home-header-elements": "ELEMENTS",
    "home-desc-elements1": "There are 4 different elements, some rarer than others. If you're a collector, look closely.",
    "home-desc-elements2": "If you're a racer, get onto the track and get testing.",
    "home-hash-title": "HASH",
    "home-hash-subtitle": "MINI GAME",
    "home-hash-button": "PLAY NOW!",

    //team
    "team": "team",
    "coming soon": "coming soon",

    //tokenomics
    "tokenomics": "tokenomics",

    //nft
    "nft": "NFT",

    //about
    "about": "about",
    "dexino": "dexino",
    "DEXINO is a virtual horse racing GameFi and DrivingFi platform that is equipped with a play-to-earn game economy to enable racehorse enthusiasts to buy, collect, breed, and race NFT horses in the Dexi-verse.": "DEXINO is a virtual horse racing GameFi and DrivingFi platform that is equipped with a play-to-earn game economy to enable racehorse enthusiasts to buy, collect, breed, and race NFT horses in the Dexi-verse.",
    "mission": "mission",
    "Our mission is to let every individual to enjoy DEXINO through fun gameplay and earn token rewards for their contributions to the ecosystem.": "Our mission is to let every individual to enjoy DEXINO through fun gameplay and earn token rewards for their contributions to the ecosystem.",
    "Players no longer have to travel to far places to visit racecourses for seeing horse racing. We are committed to bringing the most real-life virtual experience to all of our beloved players.": "Players no longer have to travel to far places to visit racecourses for seeing horse racing. We are committed to bringing the most real-life virtual experience to all of our beloved players.",
    "vision": "vision",
    "our vision to become the largest decentralized digital racecourses in the Metaverse while connecting players from worldwide and creating economic prosperity together.": "our vision to become the largest decentralized digital racecourses in the Metaverse while connecting players from worldwide and creating economic prosperity together.",
    "Inspiration": "Inspiration",
    "DEXINO is a virtual horse-racing GameFi platform inspired by ZED RUN. Horse racing is one of the most ancient of all sports.": "DEXINO is a virtual horse-racing GameFi platform inspired by ZED RUN. Horse racing is one of the most ancient of all sports.",
    "By leveraging on the strength of NFTs, we are building a highly secure ecosystem that will help racehorse enthusiasts to buy, collect, breed, and race digitally and inexpensively while in the comfort of their place.": "By leveraging on the strength of NFTs, we are building a highly secure ecosystem that will help racehorse enthusiasts to buy, collect, breed, and race digitally and inexpensively while in the comfort of their place.",
    "key highlights": "key highlights",
    "The worldwide online gaming industry was valued at USD 56 billion in 2021, revenues are still growing and will reach USD 203.1 billion through consumer spending (+5.4% YoY).": "The worldwide online gaming industry was valued at USD 56 billion in 2021, revenues are still growing and will reach USD 203.1 billion through consumer spending (+5.4% YoY).",
    "By the end of 2022, there will be 2.7 billion gamers worldwide, with 2.5 billion playing on mobile, 1.3 billion playing on PC, and 0.8 billion on consoles. The industry market is still growing and it is expected to reach USD 222.6 billion by 2024 or even surpass the three-billion mark, expanding at a CAGR of 5.6% (2020 to 2024).": " By the end of 2022, there will be 2.7 billion gamers worldwide, with 2.5 billion playing on mobile, 1.3 billion playing on PC, and 0.8 billion on consoles. The industry market is still growing and it is expected to reach USD 222.6 billion by 2024 or even surpass the three-billion mark, expanding at a CAGR of 5.6% (2020 to 2024).",
    "In spite of economic downturns and businesses across the country closing, the online gaming sector remained active and even busy. The increased presence of young professional gamers and internet penetrations have contributed to the rapid growth of the gaming industry. In 2021, the Asia-Pacific region accounted for more than 50% of the online gaming market share.": "In spite of economic downturns and businesses across the country closing, the online gaming sector remained active and even busy. The increased presence of young professional gamers and internet penetrations have contributed to the rapid growth of the gaming industry. In 2021, the Asia-Pacific region accounted for more than 50% of the online gaming market share.",
    "This has proven that there is a huge opportunity in the market for online games, precisely, games with play-to-earn features. However, in the current market, the digital horse racing platform often comes with single or limited gameplay, which makes the player feel tedious and mundane, and they gradually quit the game.": "This has proven that there is a huge opportunity in the market for online games, precisely, games with play-to-earn features. However, in the current market, the digital horse racing platform often comes with single or limited gameplay, which makes the player feel tedious and mundane, and they gradually quit the game.",
    "On the other hand, the cost of having an upgrade and breeding new offspring can be prohibitive for many people. They might find the transactions expensive due to the currency exchange.": "On the other hand, the cost of having an upgrade and breeding new offspring can be prohibitive for many people. They might find the transactions expensive due to the currency exchange.",
    "DEXINO offers people more than just a play-to-earn platform. By eliminating the limits of primitive racecourses, in DEXINO, players are allowed to not only breed and race their digital horses, but they can also own a DEXI-land where they can farm hay and host races on their own virtual racecourse for actual profit. Earning is greater than spending in DEXINO. With the support of the decentralized autonomous organization (DAO) protocol, players are safeguarded for transparency, fairness, and safety in the game.": "DEXINO offers people more than just a play-to-earn platform. By eliminating the limits of primitive racecourses, in DEXINO, players are allowed to not only breed and race their digital horses, but they can also own a DEXI-land where they can farm hay and host races on their own virtual racecourse for actual profit. Earning is greater than spending in DEXINO. With the support of the decentralized autonomous organization (DAO) protocol, players are safeguarded for transparency, fairness, and safety in the game.",

    //play-to-earn
    "play-to-earn-title1": "Play-",
    "play-to-earn-title2": "To-",
    "play-to-earn-title3": "Earn",
    "play-to-earn-desc": "DEXINO is built upon the play-to-earn (P2E) game model to deliver an outstanding gaming experience to the community. DEXINO is backed by a decentralized autonomous organization (DAO). This principle will allow for years of exciting P2E gameplay and grow the ecosystem in ways we can only imagine.",
    "play-to-earn-desc1": "In the last decade, businesses have introduced a multitude of interactive in-game products that generate billions of dollars in revenue. In-game assets were offered to players who wanted to enhance the aesthetic and performance of their characters on the platform. Generally, businesses limit the use of these in-game assets and restrict their transferability to maximize their profits.",
    "play-to-earn-desc2": "The rise of blockchain technology has redefined the online gaming situation. Players own their game characters as digital assets while having the freedom to profit from trading these assets without any restrictions. Those at the edge of this rapidly growing movement want to play games with a purpose—the prospect of earning, owning, and having a seat at the table; being financially rewarded for their skills as part of the user experience rather than just playing for fun.",

    //drive-to-earn
    "drive-to-earn-title1": "Drive-",
    "drive-to-earn-title2": "To-",
    "drive-to-earn-title3": "Earn",
    "drive-to-earn-desc": "DEXINO introduces the world’s first drive-to-earn mechanism. There are numerous outstanding online games that offer a free-to-play (F2P) and play-to-earn (P2E) model where players, or more specifically game enthusiasts, can enjoy the game while earning extra profit.",
    "drive-to-earn-desc1": "P2E has gradually brought ample value to everyone, whether experienced or ordinary players, to savor every moment and get rewarded for their time spent and game skills. Previous studies show that the number of road fatalities in the world is still concerning and increasing from year to year. Road fatalities, whether intentionally or unintentionally, are the number of deaths caused by road accidents.",
    "drive-to-earn-desc2": "According to the World Health Organization (WHO) in the global status report (2021), approximately 1.3 million people die each year as a result of road traffic crashes, and more than half of them are caused by vulnerable road users: pedestrians, cyclists, and motorcyclists.",
    "drive-to-earn-desc3": "Ensuring road safety is crucial in resolving life-threatening issues. By enforcing the drive-to-earn mechanism, DEXINO is committed to building safer surroundings and advocating safe speed, safe vehicles, and safe road users for the community.",
    "drive-to-earn-desc4": "In the drive-to-earn model, users’ driving behaviors will be traced and tracked by our monitoring software, and they will be able to earn token rewards while they drive in the city. Furthermore, the drive-to-earn model is fully compatible with DEXINO's horse racing gameplay, where driving scores are transferable for in-game EXP, allowing users to level up as a trainer or grow their NFT horses. The rewards are based on the score by the indicator—Overall Safety Score; the higher the score you achieve, the higher the prize you get.",

    //game-experience
    "game-experience-title": "Game Experience",
    "game-experience-desc": "DEXINO introduces the world’s first drive-to-earn mechanism. There are numerous outstanding online games that offer a free-to-play (F2P) and play-to-earn (P2E) model where players, or more specifically game enthusiasts, can enjoy the game while earning extra profit.",
    "game-icon-race": "Racing",
    "game-icon-readmore": "Read More",
    "game-icon-details1": "Equip your digital horse and showcase your unique skills to win!",
    "game-icon-details2": "The Racecourse in DEXINO is a PVP horse racing game mode in which the player selects their digital horse for the race. After successfully entering a match, you will sprint against 12 other players and use your skills to place in the top three and receive lucrative token rewards.",
    
    //roadmap
    "roadmap-header-title1": "Dexino",
    "roadmap-header-title2": "Roadmap",

    "roadmap-1-phase": "Q1",
    "roadmap-1-year": "2022",
    "roadmap-1-details1": "Web + Mobile 3D Racing (Closed Beta)",
    "roadmap-1-details2": "Swap Widget and RAMP Integration",
    "roadmap-1-details3": "Change Pega's Name Feature",
    "roadmap-1-details4": "Notification Feature",
    "roadmap-1-details5": "Official Release Web Game",

    //race
    "race-title": "Race",
    "results-title": "Results",
    "race-comingsoon": "Coming Soon...",
    "table-entryrequirement": "Entry Requirements",
    "table-racename": "Race Name",
    "table-hippodrome": 'Hippodrome',
    "table-distance": "Distance",
    "table-entryfee": "Entry Fee",
    "table-registerends": "Register Ends",
    "table-prize": "Prize",
    "table-registered": "Registered",



}

export default EN;

